export const KUMU_URLS = {
  PAGES: {
    B2C: {
      MAIN: "/",
      FLOW: {
        LOGIN: "/flow/login",
        CREATOR: {
          SIGN_UP: "/flow/creator/sign-up",
          PERSONAL_DETAILS: "/flow/creator/personal-details",
          SIGN_UP_COMPLETED: "/flow/creator/complete",
          CONFIRM: "/flow/creator/confirm",
        },
        MEMBER: {
          SIGN_UP: "/flow/member/sign-up",
          PERSONAL_DETAILS: "/flow/member/personal-details",
          SIGN_UP_COMPLETED: "/flow/member/complete",
          CONFIRM: "/flow/member/confirm",
        },
        RESTORE_PASSWORD: "/flow/restore",
        CHECK_INBOX: "/flow/restore/check-inbox",
      },
      CONTENT: {
        VIEWCONTENT: "/view-content-page/",
      },
      COURSES: {
        MAIN: "/courses",
      },
      SETUP_PROFILE: {
        MAIN: "/my-profile",
      },
      MEMBERSHIPS: {
        MAIN: "/memberships",
        MEMBERSHIP_PAGE: (userName?: string) => `/memberships/${userName}`,
      },
      DIGITAL_PRODUCTS: {
        MAIN: "/digital-products",
        DIGITAL_PRODUCT_BY_ID: (productId?: string) =>
          `/digital-products/${productId}`,
      },
      CREATORS_SEARCH: {
        MAIN: "/expert-search",
        SEARCH_QUERY: (query?: string) => `/expert-search?query=${query}`,
      },
      MESSAGES: {
        MAIN: "/messages",
        CREATOR_USER_ID: (userId: string) =>
          `/messages?creatorUserId=${userId}`,
      },
    },
    B2B: {
      MAIN: "/dashboard",
      AUDIENCE_INSIGHTS: "/dashboard/audience-insights",
      LIBRARY: "/dashboard/library",
      SETUP_PROFILE: "/dashboard/edit-profile",
      PROFILE_PREVIEW: "/dashboard/profile-preview",
      POSTS: {
        MAIN: "/dashboard/posts",
        NEW: "/dashboard/posts/new",
        EDIT: (postId: string) => `/dashboard/posts/edit/${postId}`,
        SETUP: (postId: string) => `/dashboard/posts/setup/${postId}`,
        SETUP_V2: (postId: string) => `/dashboard/posts/setup_v2/${postId}`,
        SOCIAL_EXPORT: "/dashboard/export",
      },
      INSPIRATION: "/dashboard/inspiration",
      MESSAGES: "/dashboard/messages",
    },
  },
  API: {
    CONTENT: {
      SOCIAL: {
        PLATFORM_CONTENT: (key: KumuSocialPlatformKey) =>
          `/api/kumu/content/social/platform_content/${key}`,
        CONVERT: "/api/kumu/content/social/convert",
        EXPORTED_FILE: (fileID: string) =>
          `/api/kumu/content/social/exported_file/${fileID}`,
        EXPORTED_FILE_FOR_DOWNLOAD: (fileID: string) =>
          `/api/kumu/content/social/exported_file_for_download/${fileID}`,
        CONVERTED_FILES: (FileNames: string) =>
          `/api/kumu/content/social/convertedfiles/${FileNames}`,
      },
    },
    USERS: {
      EXPERT_TOPICS: "/api/kumu/users/kumu-topics",
      KUMU_TOPICS: "/api/kumu/users/kumu-topics",
      UPLOAD_PROFILE_PICTURE: "/api/kumu/users/profile-picture",
    },
    CREATORS: {
      SEARCH: `/api/creators/search`,
      UPDATE_CHAT_STATUS: "/api/creators/messages",
    },
    COURSES: {
      POST: `/api/kumu/courses`,
      DELETE: (courseId: string) => `/api/kumu/courses/${courseId}`,
      PATCH: (courseId: string) => `/api/kumu/courses/${courseId}`,
      UNLINK_DIGITAL_PRODUCT: (courseId: string) =>
        `/api/kumu/courses/unlink-digital-product/${courseId}`,
      UPDATE_STATUS: (courseId: string) =>
        `/api/kumu/courses/update-status/${courseId}`,
      ADD_MODULE: (courseId: string) =>
        `/api/kumu/courses/add-module/${courseId}`,
      EDIT_SERIES_PART: (moduleId: string) =>
        `/api/kumu/courses/edit-series-part/${moduleId}`,
      PATCH_VIDEO: (courseId: string) =>
        `/api/kumu/courses/update-module-from-course/${courseId}`,
      THUMBNAIL: (courseId: string) =>
        `/api/kumu/courses/upload-thumbnail/${courseId}`,
      DELETE_MODULE_FROM_COURSE: (courseId: string) =>
        `/api/kumu/courses/delete-module-from-course/${courseId}`,
      DELETE_MODULE: (moduleId: string) =>
        `/api/kumu/courses/delete-module/${moduleId}`,
      UPDATE_MODULE: (moduleId: string) =>
        `/api/kumu/courses/update-module/${moduleId}`,
      SEND_CREDENTIALS: "/api/creators/credentials",
      LIST: "/api/kumu/courses/list",
    },
    AUDIENCE_INSIGHTS: {
      GET: `/api/kumu/audience-insights`, // BODY
    },
    KUMUFEEDBACK: {
      POST: `/api/kumu/feedback`, // BODY
    },
    TAGS: {
      CREATE_TAG: `/api/kumu/tags`,
      DELETE_TAG: (id: string) => `/api/kumu/tags/${id}`,
      PROCESS_TAGS: `/api/kumu/tags/process`,
    },
    STRIPE: {
      CREATOR: {
        CREATE_CONNECT: "/api/stripe/creator/connect",
        CONNECT_AUTH: "/api/stripe/creator/auth",
      },
      CUSTOMER: {
        CHECKOUT: "/api/stripe/customer/checkout",
        SUBSCRIPTION: "/api/stripe/customer/subscription",
      },
    },
    CMS: {
      NEWMEMBERVOICE: "/api/kumu/cms/addmembervoice",
    },
  },
  RESOURCES: {
    HELP: {
      HELPURL: "https://support.kumu.co",
    },
    TERMSOFUSE: {
      TERMSOFUSEURL: "https://www.kumu.co/terms",
    },
    PRIVACYPOLICY: {
      PRIVACYPOLICYURL: "https://www.kumu.co/privacy-policy",
    },
    CONTENT: {
      SOCIAL_PLATFORMS: "/content/social_platforms",
      SOCIAL: {
        PLATFORM_CONTENT: (key: KumuSocialPlatformKey) =>
          `/content/social/platform_content?platformKey=${key}`,
        CONVERT: "/content/social/convert",
        EXPORTED_FILE: (fileID: string) =>
          `/content/social/exported_file/${fileID}`,
        EXPORTED_FILE_FOR_DOWNLOAD: (fileID: string) =>
          `/content/social/exported_file/for_download/${fileID}`,
        CONVERTED_FILES: (files: string) =>
          `/content/social/convertedfiles/${files}`,
      },
    },
    COURSES: {
      NEW: "/v2/courses",
      THUMBNAIL: (courseId: string) =>
        `/v2/courses/upload-thumbnail/${courseId}`,
      PATCH: (courseId: string) => `/v2/courses/${courseId}`,
      PATCH_ADD_MODULE: (courseId: string) =>
        `/v2/courses/add-module/${courseId}`,
      GET: "/v2/creators/courses",
      GETBYID_OLD: (courseId: string) => `/courses/${courseId}`,
      PATCH_VIDEO: (courseId: string) => `/v2/courses/${courseId}/video`,
      DELETE_MODULE_FROM_COURSE: (courseId: string) =>
        `/v2/courses/remove-video/${courseId}`,
      DELETE_MODULE: (moduleId: string) => `/v2/courses/module/${moduleId}`,
      UNLINK_DIGITAL_PRODUCT: (courseId: string) =>
        `/v2/courses/unlink-digital-product/${courseId}`,
      DELETE: (courseId: string) => `/v2/courses/${courseId}`,
      PATCH_MODULE: (moduleId: string) => `/v2/courses/module/${moduleId}`,
      EDIT_SERIES_PART: (moduleId: string) =>
        `/v2/courses/edit-series-part/${moduleId}`,
      SEND_CREDENTIALS: (type: string) =>
        `/v2/creators/send-credentials/${type}`,
      LIST: {
        GET: "/courses/list",
      },
    },
    TOPICS: {
      GET: "/topics",
      LIST: {
        GET: "/topics/list",
      },
      CATEGORIES: {
        GET: "/topics/categories",
        LIST: {
          GET: "/topics/categories/list",
        },
      },
    },
    AUDIENCE_INSIGHTS: {
      GET_EARINGS: `/v2/audience-insights/earings`,
      GET_COMMUNITY_DEMOGRAPHICS: `/v2/audience-insights/community_demographics`,
      GET_SUSCRIBERS_CURRENT_MONTH: `/v2/audience-insights/suscribers_current_month`,
      GET_BEST_PERFORM_COURSES: `/v2/audience-insights/best_performing_courses`,
      GET_BEST_PERFORM_POSTS: `/v2/audience-insights/best_performing_posts`,
      GET_LAST_MONTH_TOTAL_COURSE_SOLD: `/v2/audience-insights/last_month_total_course_sold`,
      GET_TOTAL_PROFILE_VIEWS: `/v2/audience-insights/total_profile_views`,
      GET_TOTAL_COURSE_SOLD: `/v2/audience-insights/total_course_sold`,
      GET_TOTAL_SUBSCRIPTIONS: `/v2/audience-insights/total_subscriptions`,
      GET_SUBSCRIPTIONS_BY_YEAR: (year: number) =>
        `/v2/audience-insights/subscriptions_by_year/${year}`,
      GET_SUBSCRIPTIONS_LAST_DAYS: (daysFrom: number) =>
        `/v2/audience-insights/subscriptions_last_days/${daysFrom}`,
    },
    KUMUFEEDBACK: {
      ADDFEEDBACK: "/kumu-feedback",
    },
    KUMUCMS: {
      NEWMEMBERVOICE: "/cms/inspiration/members-voices",
    },
    USERS: {
      MAIN: "/users",
      UPLOAD_PROFILE_PICTURE: "/users/profile-picture",
      BILLING_INFO: "/users/billing_info",
      SUBSCRIPTIONS: "/v2/users/subscriptions",
    },
    CREATORS: {
      SEARCH: `/v2/creators/searchs`,
      UPDATE_CHAT_STATUS: "/v2/creators/update_chat_status",
    },
    TAGS: {
      CREATE_TAG: "/v2/tags",
      DELETE_TAG: (id: string) => `/v2/tags/${id}`,
      GET_ALL_TAGS: "/tags",
      PROCESS_TAGS: "/v2/tags/process-tags",
    },
    CHAT: {
      GET_MESSAGES: "/chat",
      GET_CHATTABLE_MEMBERS: "/chat/chattable-members",
      GET_CHATTABLE_EXPERTS: "/chat/chattable-experts",
      GET_UNREAD_CHATS: "/chat/unread-chats",
    },
    STRIPE: {
      CREATE_AND_CONNECT: "/stripe/create-and-connect-stripe-account",
      CONNECT: "/stripe/connect-stripe-account",
      AUTH: "/stripe/auth-stripe-account",
      CREATE_CHECKOUT_SESSION: "/stripe/create-checkout-session",
      TOGGLE_SUBSCRIPTION: "/stripe/subscription",
      CREATE_PLANS: "/stripe/create-plans",
    },
  },
} as const;
