import { SIXTY_URLS } from "@/utils/urls";

// Services
import { serverFetchService } from "../fetch";

const FileSystemServices = {
  newPresignedUrl: async (
    accessToken: string,
    mimeType: string,
    size: number,
    fileName: string,
    description: string,
    folderName: string,
    thumbnail: string,
    language?: string,
    format?: string,
    pages?: number
  ): Promise<Promise<Content> | null> => {
    return serverFetchService(SIXTY_URLS.FILESYSTEM.ADD_FILE, {
      method: "POST",
      body: JSON.stringify({
        mimetype: mimeType,
        size: size,
        fileName: fileName,
        description: description,
        folderName: folderName,
        thumbnail: thumbnail,
        language: language,
        format: format,
        pages: pages,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },
  updateFile: async (
    fileID: string,
    accessToken: string,
    fileName: string,
    description: string,
    language?: string,
    pages?: number
  ): Promise<Promise<Content> | null> => {
    return serverFetchService(SIXTY_URLS.FILESYSTEM.ADD_FILE, {
      method: "PATCH",
      body: JSON.stringify({
        fileID: fileID,
        fileName: fileName,
        description: description,
        language: language,
        pages: pages,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },
  uploadFilesWithPreSignedUrl: async (url: string, file: File) => {
    try {
      const response = await fetch(url, {
        method: "PUT",
        body: file,
        headers: {
          "Content-Type": file.type,
        },
      });

      if (!response.ok) {
        throw new Error("Error al subir el archivo");
      }

      return response.status;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },
  uploadFilesWithPreSignedUrl_withProgress: async (
    url: string,
    file: File,
    onProgress?: (value: number) => void
  ) => {
    try {
      return await new Promise<number>((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open("PUT", url, true);

        xhr.upload.onprogress = (event) => {
          if (event.lengthComputable) {
            const progress = (event.loaded / event.total) * 100;
            console.log(`Progress: ${progress}%`);
            if (onProgress) onProgress(progress);
          }
        };

        xhr.onload = () => {
          if (xhr.status === 200) {
            console.log("Upload success");
            resolve(xhr.status);
          } else {
            console.error("Upload error: ", xhr.statusText);
            reject(new Error("Upload error"));
          }
        };

        xhr.onerror = () => {
          console.error("Network error");
          reject(new Error("Network error"));
        };

        xhr.send(file);
      });
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },
  getFileSystemByToken: async (
    accessToken: string
  ): Promise<KumuFileSystem> => {
    return serverFetchService(`/filesystem`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },
  deleteFileById: async (
    accessToken: string,
    file_id: string
  ): Promise<KumuFileSystem> => {
    return serverFetchService(`/filesystem/file/${file_id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },
};

export { FileSystemServices };
