import { clientFetchService } from "@/services/fetch";
import { FileSystemServices } from "@/services/filesystem";
import { VideoToolsService } from "@/services/video-tools";
import { getCookie } from "@/utils/cookies";
import useMediaQuery, { desktop } from "@/utils/hooks/useMediaQuery";
import { SIXTY_URLS } from "@/utils/urls";
import { KUMU_URLS } from "@/utils/urls/kumu";
import React, {
  createContext,
  FunctionComponent,
  ReactNode,
  useState,
  useEffect,
  useContext,
} from "react";
import { useSEvents } from "../sevents/hooks";
import UserContext from "../user";

interface UploaderContextType {
  uploadFile: UploadFileType[];
  setUploadFile: React.Dispatch<React.SetStateAction<UploadFileType[]>>;
  enableUploadV2: boolean;
  fileSystemContext?: KumuFileSystem;
}

const UploaderContext = createContext<UploaderContextType | undefined>(
  undefined
);

type Props = {
  children: ReactNode;
};

export const UploaderProvider: FunctionComponent<Props> = ({ children }) => {
  const [uploadFile, setUploadFile] = useState<UploadFileType[]>([]);
  const [processedFiles, setProcessedFiles] = useState<Set<string>>(new Set());
  const [enableUploadV2, setEnabledUploadV2] = useState<boolean>(false);
  const [fileSystemContext, setFileSystemContext] = useState<KumuFileSystem>();
  const isDesktop = useMediaQuery(desktop);
  const { user } = useContext(UserContext);
  const creatorID = user.creator?.id ?? "";
  const { uploadFileSevent, successFileUploadedSevent } = useSEvents();
  useEffect(() => {
    // TO ENABLE THIS FEATURE FLAG YOU NEED TO ADD updateV2 COOKIE ON THE BROWSER WITH true VALUE
    const cookiev2 = getCookie(document.cookie, "updateV2");
    setEnabledUploadV2(cookiev2 === "true" ?? false);
    loadFileSystem();
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      const filesInProgress = uploadFile.some(
        (file) => file.progress >= 0 && file.progress < 100
      );
      if (filesInProgress) {
        const message =
          "You have files being uploaded. Are you sure you want to leave?";
        event.preventDefault();
        event.returnValue = message;
        return message;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [uploadFile]);

  useEffect(() => {
    const pendingFiles = uploadFile.filter(
      (file) => !processedFiles.has(file.phisicalName)
    );

    const processPendingFiles = async () => {
      for (let file of pendingFiles) {
        setProcessedFiles((prevProcessedFiles) =>
          new Set(prevProcessedFiles).add(file.phisicalName)
        );

        try {
          await processUpload(file);
        } catch (error) {
          console.error(`Error processing file ${file.fileName}:`, error);
        }
      }
    };

    if (pendingFiles.length > 0) {
      processPendingFiles();
    }
  }, [uploadFile]);

  //MAIN PROCESS
  const processUpload = async (file: UploadFileType) => {
    //file generation in fileSystem
    uploadFileSevent({
      payload: {
        creatorID,
        fileName: file.fileName,
        mimeType: file.mimeType,
        size: file.size,
      },
    });
    const upload: any = await generateFileFileSystem(file);
    //S3 upload process
    await manageS3Put(upload.url, file);
    //if the process comes from post/course creation we run the vimeo upload process
    if (file.origin === "CONTENTCREATION") {
      //s3 get link process
      const s3GetPresignedUrl: any = await manageS3Get(upload.fileCreated.id);
      if (s3GetPresignedUrl && s3GetPresignedUrl.data.link) {
        //async vimeo upload process
        const vimeo: any = await vimeoHandlerWithPresignedURL({
          ...upload,
          presignedUrl: s3GetPresignedUrl.data.link,
        });
        //update of coursemodule or post acording to id
        if (file.moduleId) {
          console.log(
            "coursemodule update implementation",
            vimeo.video.result,
            vimeo.duration,
            file.moduleId
          );
        } else {
          console.log(
            "post update implementation",
            vimeo.video.result,
            vimeo.duration,
            file.courseId
          );
        }
      }
    }
    //when the process it`s done remove the file from the list
    removeFile(file.phisicalName);
  };

  //UTILS
  const updateProgress = (phisicalName: string, progress: number) => {
    setUploadFile((prevFiles) =>
      prevFiles.map((file) =>
        file.phisicalName === phisicalName ? { ...file, progress } : file
      )
    );
  };
  const removeFile = (phisicalName: string) => {
    setUploadFile((prevFiles) =>
      prevFiles.filter((file) => file.phisicalName !== phisicalName)
    );
    setProcessedFiles((prevProcessedFiles) => {
      const newProcessedFiles = new Set(prevProcessedFiles);
      newProcessedFiles.delete(phisicalName);
      return newProcessedFiles;
    });
  };

  const thumbnailLiveGenerator = async (fileVideo: File) => {
    const thumbnail = await VideoToolsService.generateThumbnail(
      isDesktop,
      fileVideo,
      1
    );
    const formData = new FormData();
    formData.append("thumbnail", thumbnail);
    const thumbnail_url: { url: string } = await clientFetchService(
      SIXTY_URLS.API.COURSES_UPLOAD_THUMBNAIL,
      {
        method: "POST",
        body: formData,
      }
    );
    return thumbnail_url.url;
  };

  const getVideoDurationFromFile = (presignedURL?: string) => {
    return new Promise((resolve, reject) => {
      if (presignedURL) {
        const video = document.createElement("video");
        video.preload = "metadata";
        video.onloadedmetadata = () => {
          window.URL.revokeObjectURL(video.src);
          resolve(video.duration);
        };
        video.onerror = (error) => {
          reject(error);
        };
        video.src = presignedURL;
      }
    });
  };

  //FILE SYSTEM FUNCTION
  const generateFileFileSystem = async (file: UploadFileType) => {
    const thumbnailFinal = !file.thumbnailUrl
      ? await thumbnailLiveGenerator(file.file)
      : file.thumbnailUrl;

    return await clientFetchService(SIXTY_URLS.API.FILESYSTEM, {
      method: "POST",
      body: JSON.stringify({
        mimeType: file.mimeType,
        size: file.size,
        fileName: file.fileName,
        description: file.description,
        folderName: "folderName",
        thumbnail: thumbnailFinal,
      }),
    });
  };

  const loadFileSystem = async () => {
    const currentFileSystem: any = await clientFetchService(
      SIXTY_URLS.API.FILESYSTEM,
      {
        method: "GET",
      }
    );
    setFileSystemContext(currentFileSystem);
  };

  //S3 FUNCTIONS
  const manageS3Put = async (presignedUrl: string, file: UploadFileType) => {
    await FileSystemServices.uploadFilesWithPreSignedUrl_withProgress(
      presignedUrl,
      file.file,
      (value: number) => {
        updateProgress(file.phisicalName, Math.round(value));
      }
    );
    loadFileSystem();
    successFileUploadedSevent({
      payload: {
        creatorID,
      },
    });
  };

  const manageS3Get = async (id: string) => {
    return await clientFetchService(
      KUMU_URLS.API.CONTENT.SOCIAL.EXPORTED_FILE(id),
      {
        method: "GET",
      }
    );
  };

  //VIMEO FUNCTION
  const vimeoHandlerWithPresignedURL = async (
    selectedFileFromFileSystem: KumuFile & { presignedUrl: string }
  ) => {
    try {
      await clientFetchService(SIXTY_URLS.API.VIMEO.CREATE_URI_FROM_URL, {
        method: "POST",
        body: JSON.stringify({
          size: selectedFileFromFileSystem.size,
          presignedURL: selectedFileFromFileSystem.presignedUrl,
          name: selectedFileFromFileSystem.filename,
        }),
      })
        .then(async (vimeo_response: any) => {
          const { uri } = vimeo_response as {
            uri: string;
          };
          const currentDuration = (await getVideoDurationFromFile(
            selectedFileFromFileSystem.presignedUrl
          )) as number | undefined;
          const uriParts = uri.split("/");
          const videoID = uriParts[uriParts.length - 1];
          //check resolution
          return {
            video: {
              result: { url: `https://player.vimeo.com/video/${videoID}` },
            },
            duration: currentDuration,
          };
        })
        .catch((resp) => {
          console.log("FILE UPLOAD RESULT", resp);
        });
    } catch (error) {
      console.log("Error uploading video", error);
    }
  };

  const value: UploaderContextType = {
    uploadFile,
    setUploadFile,
    enableUploadV2,
    fileSystemContext,
  };

  return (
    <UploaderContext.Provider value={value}>
      {children}
    </UploaderContext.Provider>
  );
};

export default UploaderContext;

// UseUploadFile hook
export const useUploaderContext = () => {
  const context = useContext(UploaderContext);
  if (!context) {
    throw new Error("useUploaderContext must be used within a UploadFile");
  }
  return context;
};
